var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[(_vm.element)?_c('div',[_c('div',{staticClass:"card",staticStyle:{"padding":"15px"}},[_c('div',{staticClass:"card-image",staticStyle:{"padding":"15px, position: relative"}},[(!_vm.isLoading)?_c('div',{staticClass:"checkerboard-background",style:({
                        position: 'absolute',
                        top: (!_vm.isLoading ? (100 - _vm.displayedDimensions.height) / 2 : 0) + '%',
                        left: (!_vm.isLoading ? (100 - _vm.displayedDimensions.width) / 2 : 0) + '%',
                        width: (!_vm.isLoading ? _vm.displayedDimensions.width : 100) + '%',
                        height: (!_vm.isLoading ? _vm.displayedDimensions.height : 100) + '%',
                    })},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.coloredSvgPreviewUrl ? _vm.coloredSvgPreviewUrl : _vm.imageFileUrl,"alt":_vm.element.description}})]):_vm._e(),_c('b-loading',{staticClass:"primary-loading",attrs:{"is-full-page":false,"active":_vm.isLoading}}),_c('div',{staticStyle:{"padding-top":"75%"}})],1),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.localLabel)+" "),_c('b-taglist',_vm._l((_vm.tags),function(tag,index){return _c('b-tag',{key:index,attrs:{"type":"is-primary"}},[_vm._v(_vm._s(_setup.store.tagTranslation(tag, _vm.$i18n.locale)))])}),1)],1),(_vm.isSvg)?_c('hr'):_vm._e(),(_vm.isSvg && _vm.colors)?_c(_setup.PalettePicker,{model:{value:(_vm.colors),callback:function ($$v) {_vm.colors=$$v},expression:"colors"}}):_vm._e()],1),_c('footer',{staticClass:"card-footer"},[(_vm.isSvg)?_c('a',{staticClass:"card-footer-item",on:{"click":_vm.downloadPng}},[_vm._v(_vm._s(_vm.$t('EDIT.EXPORT.PNG')))]):_vm._e(),(_vm.isPhoto)?_c('a',{staticClass:"card-footer-item",on:{"click":_vm.downloadPhoto}},[_vm._v(_vm._s(_vm.$t('EDIT.EXPORT.PHOTO')))]):_vm._e(),(_vm.isSvg)?_c('a',{staticClass:"card-footer-item",attrs:{"href":_vm.coloredSvgPreviewUrl,"download":_vm.elementId + '.svg'}},[_vm._v(_vm._s(_vm.$t('EDIT.EXPORT.SVG')))]):_vm._e(),(_vm.isTemplate)?_c('router-link',{staticClass:"card-footer-item primary",attrs:{"to":{ name: 'editor', params: { lang: _vm.$i18n.locale, templateId: _vm.elementId } },"download":_vm.elementId + '.svg'}},[_vm._v(_vm._s(_vm.$t('BUTTONS.START_EDITION')))]):_vm._e(),(_vm.$isBacktivisda)?_c('a',{staticClass:"card-footer-item",on:{"click":_vm.deleteOrRestoreImage}},[_vm._v(" "+_vm._s(_vm.element.status === 'deleted' ? 'Restaurer' : 'Supprimer')+" ")]):_vm._e()],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }