var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'gallery-card': true,
        card: true,
        box: true,
        'is-flex': true,
        'is-flex-direction-column': true,
        'is-justify-content-space-between': true,
        [_vm.element['status']]: true,
    },staticStyle:{"width":"120px","margin":"5px"},attrs:{"data-symbol-preview":_vm.element.id},on:{"click":_vm.handleClick}},[_c('div',{staticClass:"card-image",attrs:{"data-badge":_vm.element['status'] && _vm.element['status'] !== 'unchanged' ? _vm.element['status'] : _vm.element['isNew'] ? _vm.$t('BUTTONS.NEW') : ''}},[_c('figure',{staticClass:"image contain-image"},[_c(_setup.LazyLoadImage,{attrs:{"src":_setup.store.imageThumbnail(_vm.element.id, _vm.path),"alt":_vm.element.description}})],1)]),_c('div',{staticClass:"card-content"},[_vm._v(" "+_vm._s(_vm.element.localLabel)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }